<template>
  <li class="row brdr-top-1 brdr-bottom-1 mx0 p10 pb5 pt5 bg-cl-white">
    <div class="blend pr10">
      <product-image :microcart="microcart" :image="product.image" />
    </div>
    <div class="flex pr0 relative" :class="[ isSimple ? 'simple-product-info-box' : 'product-info-box' ]">
      <div class="p0 flex pl30 col-xs-3 center-xs column">
        <router-link
          class="fs-medium weight-600 pb10 name cl-black"
          :to="productLink"
          data-testid="productLink"
          @click.native="$store.commit('ui/setMicrocart', false)"
        >
          {{ product.name | htmlDecode }}
        </router-link>
        <div class="p0 row">
          <p class="m0 price-left cl-black h5">
            {{ $t('Price') }}:
          </p>
          <div class="prices" v-if="!displayItemDiscounts || !isOnline">
            <span class="cl-error price-special pr5" v-if="product.special_price">{{ product.price_incl_tax * product.qty | price(storeView) }}</span>
            <span class="price-original" v-if="product.special_price">' ' + {{ product.original_price_incl_tax * product.qty | price(storeView) }}</span>
            <span class="fs-medium weight-500 price-regular cl-black" v-else data-testid="productPrice">
              {{ (product.original_price_incl_tax ? product.original_price_incl_tax : product.price_incl_tax) * product.qty | price(storeView) }}
            </span>
          </div>
          <div class="prices" v-else-if="isOnline && product.totals">
            <span class="cl-error price-special pr5" v-if="product.totals.discount_amount || isSpecialPrice">
              <!-- <span v-if="product.totals.discount_amount">{{ product.totals.row_total - product.totals.discount_amount + product.totals.tax_amount | price(storeView) }}</span> -->
              <span v-if="product.totals.discount_amount">{{ product.totals.row_total_incl_tax - product.totals.discount_amount | price(storeView) }}</span>
              <span v-else>{{ product.totals.row_total_incl_tax | price(storeView) }}</span>
            </span>
            <span class="h6 price-original" v-if="product.totals.discount_amount && !isSpecialPrice">
              {{ product.totals.row_total_incl_tax | price(storeView) }}
            </span>
            <span class="fs-medium weight-500 price-regular cl-black" v-if="!product.totals.discount_amount && !isSpecialPrice">
              {{ product.totals.row_total_incl_tax | price(storeView) }}
            </span>
          </div>
          <div class="prices" v-else>
            <span class="fs-medium weight-500 price-regular cl-black">
              {{ (product.regular_price || product.price_incl_tax) * product.qty | price(storeView) }}
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="col-xs-2">
        <p class="m0 cl-black h5">
          {{ $t('Quantity') }}:
        </p>
        <product-quantity
          class="mt20"
          :value="productQty"
          :min-quantity="minQuantity"
          :max-quantity="maxQuantity"
          :increments-step="incrementStep"
          :loading="isStockInfoLoading"
          :is-simple-or-configurable="isSimpleOrConfigurable"
          @input="updateProductQty"
          @click="updateProductQty"
          @error="handleQuantityError"
        />
      </div> -->
      <div class="h6 cl-error" v-if="product.errors && Object.keys(product.errors).length > 0">
        {{ product.errors | formatProductMessages }}
      </div>
      <div class="col-xs-4 label-options" v-if="isTotalsActive && !isSimple">
        <div class="cl-black h5 text-options" v-for="(opt, index) in getTotalsOptions" v-if="index !== 2" :key="index">
          <span class="opn capitalize pr5">{{ $t(opt.label) }} </span>
          <span class="opv weight-900 cl-light-gray uppercase" v-html="opt.value" />
        </div>
      </div>
      <div class="col-xs-4 label-options" v-if="isTotalsActive && !isSimple">
        <div v-if="product.sirental_price" class="cl-black h5 text-options">
          <span class="cl-black text-options">{{ $t('Hire') }}:</span>
          <span class="opn cl-light-gray weight-900">{{ getPreatyPeriod }}</span>
        </div>
        <template>
          <div class="cl-black h5 text-options" v-for="(opt, index) in getTotalsOptions" v-show="index === 2" :key="index">
            <span class="opn capitalize pr5">{{ $t(opt.label) }} </span>
            <span class="opv weight-900 cl-light-gray uppercase" v-html="opt.value" />
          </div>
        </template>
      </div>
      <div class="col-xs-4 label-options" v-if="product.type_id === 'simple'">
        <div v-if="product.size" class="cl-black h5 text-options">
          <span class="opn capitalize pl20 pr5">{{ getAttributeLabel('size') }} </span>
          <span class="opv weight-900 cl-light-gray uppercase" v-html="getAttributeLabelName('size')" />
        </div>
        <div v-if="product.shoe_size" class="cl-black h5 text-options">
          <span class="opn capitalize pl20 pr5">{{ getAttributeLabel('shoe_size') }} </span>
          <span class="opv weight-900 cl-light-gray uppercase" v-html="getAttributeLabelName('shoe_size')" />
        </div>
        <div class="cl-black h5 text-options">
          <span class="opn capitalize pl20 pr5">{{ getAttributeLabel('marketplace_condition') }} </span>
          <span class="opv weight-900 cl-light-gray uppercase" v-html="getAttributeLabelName('marketplace_condition')" />
        </div>
      </div>
      <!-- <div class="h5 cl-black pt5" data-testid="productSku">
        {{ $t('Product code') }}: {{ product.sku }}
      </div> -->
      <div class="icons top-xs">
        <i class="icon-delet-product-from-wishlisth__path1 cl-light-gray" @click="removeItem" />
        <!-- <i class="icon-edit cl-light-gray" /> -->
      </div>
    </div>
  </li>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import Product from '@vue-storefront/core/compatibility/components/blocks/Microcart/Product'

import ProductQuantity from 'theme/components/core/ProductQuantity.vue'
import ProductImage from 'theme/components/core/ProductImage'
import { onlineHelper } from '@vue-storefront/core/helpers'
import { ProductOption } from '@vue-storefront/core/modules/catalog/components/ProductOption'
import { getThumbnailForProduct, getProductConfiguration } from '@vue-storefront/core/modules/cart/helpers'
import getTryOnFakeDate from 'theme/mixins/getTryOnFakeDate.ts'

export default {
  data () {
    return {
      maxQuantity: 0,
      quantityError: false,
      isStockInfoLoading: false
    }
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    microcart: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProductImage,
    ProductQuantity
  },
  mixins: [Product, ProductOption, getTryOnFakeDate],
  computed: {
    ...mapState({
      isMicrocartOpen: state => state.ui.microcart
    }),
    ...mapGetters({
      attributeListByCode: 'attribute/attributeListByCode'
    }),
    getPreatyPeriod () {
      if (this.getPeriod === 2) {
        return this.$t('Trying on')
      } else {
        return this.getPeriod === 1
          ? this.getPeriod + ' ' + this.$t('day')
          : this.getPeriod + 1 + ' ' + this.$t('days')
      }
    },
    getPeriod () {
      if (!this.product.totals || !this.product.totals.base_price_incl_tax || !this.product.sirental_price) return null
      let currType = this.product.sirental_price.find(type => (type.price && Number(type.price) === this.product.totals.base_price_incl_tax) || (type.price && Number(type.final_price) === this.product.totals.base_price_incl_tax))
      let plusOneDayToCurrType = currType && currType.period.split('')
      return plusOneDayToCurrType
        ? Number(plusOneDayToCurrType[0])
        : 0
    },
    minQuantity () {
      return this.product.stock && this.product.stock.min_sale_qty ? this.product.stock.min_sale_qty : 1
    },
    incrementStep () {
      return this.product.stock && this.product.stock.qty_increments ? this.product.stock.qty_increments : 1
    },
    thumbnail () {
      return this.getThumbnail(this.product.image, 250, 250)
    },
    hasProductInfo () {
      return this.product.info && Object.keys(this.product.info).length > 0
    },
    hasProductErrors () {
      const errors = this.product.errors || {}
      const errorsValuesExists = Object.keys(errors).filter(errorKey => errors[errorKey]).length > 0
      return errorsValuesExists
    },
    isTotalsActive () {
      return this.isOnline && this.product.totals && this.product.totals.options
    },
    isOnline () {
      return onlineHelper.isOnline
    },
    productsAreReconfigurable () {
      return config.cart.productsAreReconfigurable && this.product.type_id === 'configurable' && this.isOnline
    },
    displayItemDiscounts () {
      return config.cart.displayItemDiscounts
    },
    image () {
      return {
        loading: this.thumbnail,
        src: this.thumbnail
      }
    },
    configuration () {
      return getProductConfiguration(this.product)
    },
    productLink () {
      return formatProductLink(this.product, currentStoreView().storeCode)
    },
    productQty () {
      return this.product.qty
    },
    isSimple () {
      return this.product.type_id === 'simple'
    },
    isSimpleOrConfigurable () {
      return ['simple', 'configurable'].includes(this.product.type_id)
    },
    isUpdateCartDisabled () {
      return this.quantityError ||
        this.isStockInfoLoading ||
        (this.isOnline && !this.maxQuantity && this.isSimpleOrConfigurable)
    },
    storeView () {
      return currentStoreView()
    },
    isSpecialPrice () {
      return this.product.special_to_date
        ? !(!!this.product.special_to_date && Date.now() > Date.parse(this.product.special_to_date))
        : false
    }
    // getTotalsOptions() {
    //   if(!(this.product.product_option && this.product.product_option.extension_attributes)) return this.product.totals.options

    //   const newTotals = JSON.parse(JSON.stringify(this.product.totals.options))

    //   const endDateLabel = "End Date:"
    //   const endDateId = this.product.custom_options.find(option => option.title === endDateLabel).option_id.toString()
    //   const endDate = this.product.product_option.extension_attributes.custom_options.find(option => option.option_id === endDateId).option_value

    //   const startDateLabel = "Start Date:"
    //   const startDateId = this.product.custom_options.find(option => option.title === startDateLabel).option_id.toString()
    //   const startDate = this.product.product_option.extension_attributes.custom_options.find(option => option.option_id === startDateId).option_value

    //   const options = {
    //     year: 'numeric',
    //     month: 'long',
    //     day: 'numeric',
    //   };

    //   const newStartDate = new Date(startDate).toLocaleDateString(this.storeView.i18n.defaultLocale, options)
    //   const newEndDate = new Date(endDate)
    //   const fakeEndDate = new Date(newEndDate.setDate(newEndDate.getDate() - 1)).toLocaleDateString(this.storeView.i18n.defaultLocale, options)

    //   console.log('newEndDate', newEndDate)
    //   console.log('fakeEndDate', fakeEndDate)

    //   newTotals.find(t => t.label === startDateLabel).value = newStartDate
    //   newTotals.find(t => t.label === endDateLabel).value = fakeEndDate

    //   return newTotals
    // }
  },
  methods: {
    updateProductQty (qty) {
      this.updateQuantity(qty)
    },
    removeFromCart () {
      this.$store.dispatch('cart/removeItem', { product: this.product })
    },
    updateQuantity (quantity) {
      this.$store.dispatch('cart/updateQuantity', { product: this.product, qty: quantity })
    },
    async getQuantity (product) {
      if (this.isStockInfoLoading) return // stock info is already loading
      this.isStockInfoLoading = true
      try {
        const validProduct = product || this.product
        const res = await this.$store.dispatch('stock/check', {
          product: validProduct,
          qty: this.productQty
        })
        return res.qty
      } finally {
        this.isStockInfoLoading = false
      }
    },
    handleQuantityError (error) {
      this.quantityError = error
    },
    getAttributeLabel (value) {
      return this.attributeListByCode[value] ? this.attributeListByCode[value].frontend_label : ''
    },
    getAttributeLabelName (type) {
      if (this.attributeListByCode && this.attributeListByCode[type]) {
        let items = this.attributeListByCode[type].options.filter((option) => { return option.value === String(this.product[type]) })
        if (items && items.length) {
          return items[0].label
        } else {
          return false
        }
      }
    }
  },
  watch: {
    isOnline: {
      async handler (isOnline) {
        if (isOnline) {
          const maxQuantity = await this.getQuantity()
          this.maxQuantity = maxQuantity
        }
      }
    },
    isMicrocartOpen: {
      async handler (isOpen) {
        if (isOpen) {
          const maxQuantity = await this.getQuantity()
          this.maxQuantity = maxQuantity
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

  /deep/ .blend {
    flex-basis: 12%;
    max-width: 7%;
    .product-image__thumb {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .shadow-li {
    box-shadow: 0px 3px 10px #7E7E7E69;
  }

  .remove-product {
    position: absolute;
    right: 10px;
  }
  .label-options {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .name {
    display: flex;
    font-size: 13px;
    color: #707070;
    text-transform: uppercase;
    text-align: left;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
  .icons {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .prices {
    display: flex;
    flex-direction: row;
    @media (max-width: 767px) {
      padding: 0;
      font-size: 12px;
    }
  }
  .col-xs-4 {
    flex-basis: 38.333333%;
    max-width: 38.333333%;
  }
  .text-options {
    font-size: 12px;
    padding: 5px 0;
  }
  .icon-edit {
    font-size: 48px;
    transform: translateY(5px);
  }
  .price-regular {
    display: flex;
    align-items: flex-end;
    font-size: 14px;
    font-weight: 800;
    color: #707070;
    text-transform: uppercase;
  }
  .price-special {
    font-size: 14px;
    display: flex;
    align-items: flex-end;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
  .icon-delet-product-from-wishlisth__path1 {
    cursor: pointer;
    font-size: 28px;
  }
  .price-original {
    font-size: 14px;
    text-decoration: line-through;
  }
  .icons {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .price-left {
    display: flex;
    align-items: flex-end;
    padding-right: 5px;
    padding-left: 8px;
  }
  .product-info-box {
    justify-content: space-between;
    flex: 1
  }
  .simple-product-info-box {
    flex: 1
  }
</style>
